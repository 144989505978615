define("ux-workshop-toolkit/templates/components/form-input-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "ux-workshop-toolkit/templates/components/form-input-wrapper.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "error text-danger");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "error", ["loc", [null, [4, 34], [4, 43]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();

    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 7
          }
        },
        "moduleName": "ux-workshop-toolkit/templates/components/form-input-wrapper.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'for');
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "for", ["concat", [["subexpr", "concat", [["get", "elementId", ["loc", [null, [1, 21], [1, 30]]], 0, 0, 0, 0], "-name"], [], ["loc", [null, [1, 12], [1, 40]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "labelContent", ["loc", [null, [1, 42], [1, 58]]], 0, 0, 0, 0], ["inline", "input", [], ["type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [2, 13], [2, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "autocomplete", ["subexpr", "@mut", [["get", "type", ["loc", [null, [2, 52], [2, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [2, 63], [2, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "min", "0", "focus-out", "showErrors", "id", ["subexpr", "concat", [["get", "elementId", ["loc", [null, [2, 111], [2, 120]]], 0, 0, 0, 0], "-name"], [], ["loc", [null, [2, 103], [2, 129]]], 0, 0]], ["loc", [null, [2, 0], [2, 131]]], 0, 0], ["block", "if", [["get", "showError", ["loc", [null, [3, 6], [3, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [5, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());

  _exports.default = _default;
});