define("ux-workshop-toolkit/routes/your-workshop-scripts", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ux-workshop-toolkit/mixins/reset-scroll-position"], function (_exports, _authenticatedRouteMixin, _resetScrollPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      RSVP = Ember.RSVP;

  var _default = Route.extend(_authenticatedRouteMixin.default, _resetScrollPosition.default, {
    model: function model() {
      return RSVP.hash({
        workshops: this.get('store').findAll('workshop')
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.clearMessages();
      }
    }
  });

  _exports.default = _default;
});