define("ux-workshop-toolkit/services/media-queries", ["exports", "ember-cli-media-queries/services/media-queries"], function (_exports, _mediaQueries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/services/media-queries.js
  var _default = _mediaQueries.default.extend({
    media: {
      mobile: '(max-width: 767px)',
      tablet: '(min-width: 768px) and (max-width: 1023px)',
      desktop: '(min-width: 1024px) and (max-width: 1200px)',
      jumbo: '(min-width: 1201px)',
      upToTablet: '(max-width: 1023px)'
    }
  });

  _exports.default = _default;
});