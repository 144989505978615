define("ux-workshop-toolkit/controllers/login-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      RSVP = Ember.RSVP,
      A = Ember.A;

  var _default = Controller.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var store = this.get('store');
      var draftWorkshops = store.peekAll('workshop');
      var readyToSaveFlag = false;
      draftWorkshops.forEach(function (workshop) {
        if (workshop.readyToSave === true) {
          readyToSaveFlag = true;
          workshop.set('readyToSave', false);
          var saveWorkshop = new RSVP.Promise(function (resolve) {
            workshop.save().then(resolve(workshop));
          });
          saveWorkshop.then(function () {
            if (workshop.get('blocks').lenght > 0) {
              var blockList = A([]);
              workshop.get('blocks').forEach(function (block, index) {
                blockList.push(new RSVP.Promise(function (resolve) {
                  block.set('parentid', workshop.get('id'));
                  block.set('sortorder', index);
                  block.save().then(resolve());
                }));
              });
              RSVP.Promise.all(blockList).then(function () {
                _this.transitionToRoute('new-workshop').then(function () {// let workshopTitle = workshop.get('introtitle');
                  // newRoute.controller.set('noticeMessage', `"${workshopTitle}" - has been saved successfully`);
                }); // this.transitionToRoute('your-workshop-scripts').then(() => {
                //   let workshopTitle = workshop.get('introtitle');
                //   newRoute.controller.set('noticeMessage', `"${workshopTitle}" - has been saved successfully`);
                // });

              });
            } else {
              if (workshop.get('id')) {
                _this.transitionToRoute('edit-workshop', workshop.get('id')).then(function () {// let workshopTitle = workshop.get('introtitle');
                  // newRoute.controller.set('noticeMessage', `"${workshopTitle}" - has been saved successfully`);
                });
              } else {
                _this.transitionToRoute('new-workshop').then(function () {// let workshopTitle = workshop.get('introtitle');
                  // newRoute.controller.set('noticeMessage', `"${workshopTitle}" - has been saved successfully`);
                });
              } // this.transitionToRoute('your-workshop-scripts').then(() => {
              //     let workshopTitle = workshop.get('introtitle');
              //     newRoute.controllerFor('login').set('readyToSave', false);
              //     newRoute.controller.set('noticeMessage', `"${workshopTitle}" - has been saved successfully`);
              //   });

            }
          });
        }
      });

      if (!readyToSaveFlag) {
        this.transitionToRoute('your-workshop-scripts');
      }
    }
  });

  _exports.default = _default;
});