define("ux-workshop-toolkit/components/ui-sortable", ["exports", "ember-ui-sortable/components/ui-sortable"], function (_exports, _uiSortable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiSortable.default;
    }
  });
});