define("ux-workshop-toolkit/router", ["exports", "ux-workshop-toolkit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmberRouter = Ember.Router,
      EmberRun = Ember.run,
      inject = Ember.inject;
  var appRouter = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      EmberRun.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _this.get('metrics').trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  appRouter.map(function () {
    this.route('new-workshop');
    this.route('your-workshop-scripts');
    this.route('login');
    this.route('register');
    this.route('profile');
    this.route('edit-workshop', {
      path: '/edit-workshop/:id'
    });
    this.route('forgot-password');
    this.route('forgot-password-success');
    this.route('reset-password'); // this.route('reset-password-success');

    this.route('ux-tips');
    this.route('login-success');
    this.route('feedback');
    this.route('delete-workshop');
    this.route('reset-password-success');
  });
  var _default = appRouter;
  _exports.default = _default;
});