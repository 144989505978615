define("ux-workshop-toolkit/templates/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 6
          }
        },
        "moduleName": "ux-workshop-toolkit/templates/register.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel white small margin-top-30 margin-bottom-30 login-form");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "button primary");
        dom.setAttribute(el4, "type", "submit");
        var el5 = dom.createTextNode("Register");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "margin-top-10 no-margin-bottom error text-danger");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("small");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(element0, 5, 5);
        morphs[4] = dom.createMorphAt(element0, 7, 7);
        morphs[5] = dom.createMorphAt(element0, 9, 9);
        morphs[6] = dom.createUnsafeMorphAt(dom.childAt(element0, [13, 1]), 0, 0);
        return morphs;
      },
      statements: [["element", "action", ["registerMember", ["get", "model", ["loc", [null, [3, 40], [3, 45]]], 0, 0, 0, 0]], ["on", "submit"], ["loc", [null, [3, 14], [3, 59]]], 0, 0], ["inline", "form-input-wrapper", [], ["class", "margin-bottom-30", "id", "firstname", "value", ["subexpr", "@mut", [["get", "model.firstname", ["loc", [null, [4, 79], [4, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter First Name"], ["loc", [null, [4, 12], [4, 128]]], 0, 0], ["inline", "form-input-wrapper", [], ["class", "margin-bottom-30", "id", "lastname", "value", ["subexpr", "@mut", [["get", "model.lastname", ["loc", [null, [5, 78], [5, 92]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Last Name"], ["loc", [null, [5, 12], [5, 125]]], 0, 0], ["inline", "form-input-wrapper", [], ["class", "margin-bottom-30", "id", "organization", "value", ["subexpr", "@mut", [["get", "model.organization", ["loc", [null, [6, 82], [6, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Organisation"], ["loc", [null, [6, 12], [6, 136]]], 0, 0], ["inline", "form-input-wrapper", [], ["class", "margin-bottom-30", "id", "new_email", "value", ["subexpr", "@mut", [["get", "model.email", ["loc", [null, [7, 79], [7, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Email*", "error", ["subexpr", "@mut", [["get", "errors.model.email", ["loc", [null, [7, 125], [7, 143]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 12], [7, 145]]], 0, 0], ["inline", "form-input-wrapper", [], ["class", "margin-bottom-30", "type", "password", "id", "new_password", "value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [8, 98], [8, 112]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Password*", "error", ["subexpr", "@mut", [["get", "errors.model.password", ["loc", [null, [8, 150], [8, 171]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 12], [8, 173]]], 0, 0], ["content", "errorMessage", ["loc", [null, [11, 23], [11, 41]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());

  _exports.default = _default;
});