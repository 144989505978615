define("ux-workshop-toolkit/components/header-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    tagName: '',
    isReady: 'logo',
    src: 'https://s3-ap-southeast-2.amazonaws.com/sbw-prod-static-assets/Sitback-logo_Metro-Nova_with-padding.png'
  });

  _exports.default = _default;
});