define("ux-workshop-toolkit/components/hubspot-form", ["exports", "ember-hubspot-form/components/hubspot-form"], function (_exports, _hubspotForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hubspotForm.default;
    }
  });
});