define("ux-workshop-toolkit/components/time-input-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: ['form-group', 'floating-label'],
    classNameBindings: ['isActive:is-populated:'],
    showError: false,
    isActive: computed('value', function () {
      return !!this.get('value') ? true : false;
    }),
    type: 'text',
    showErrors: function showErrors() {
      this.set('showError', true);
    },
    actions: {
      handleFocusOut: function handleFocusOut() {
        this.showErrors();

        if (this.get('type') === 'number') {
          this.sendAction('updateTime', this.get('value'));
        }
      }
    }
  });

  _exports.default = _default;
});