define("ux-workshop-toolkit/controllers/login", ["exports", "ember-validations"], function (_exports, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      inject = Ember.inject;

  var _default = Controller.extend(_emberValidations.default, {
    readyToSave: false,
    username: null,
    password: null,
    errorMessage: null,
    successMessage: '',
    noticeMessage: '',
    sourceRoute: null,
    clearMessages: function clearMessages() {
      this.set('errorMessage', '');
      this.set('successMessage', '');
      this.set('noticeMessage', '');
    },
    session: inject.service('session'),
    validations: {
      'username': {
        presence: {
          message: 'Username can not be empty.'
        }
      },
      'password': {
        presence: {
          message: 'Password can not be empty.'
        }
      }
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _this$getProperties = this.getProperties('username', 'password'),
            username = _this$getProperties.username,
            password = _this$getProperties.password;

        this.set('successMessage', '');
        this.set('noticeMessage', '');
        this.set('errorMessage', '');
        this.validate().then(function () {
          _this.get('session').authenticate('authenticator:custom', username, password).catch(function (reason) {
            _this.set('errorMessage', reason);
          });
        }).catch(function () {
          _this.set('errorMessage', 'Required information is missing.');
        });
      }
    }
  });

  _exports.default = _default;
});