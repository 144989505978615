define("ux-workshop-toolkit/routes/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route;

  var _default = Route.extend({});

  _exports.default = _default;
});