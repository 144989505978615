define("ux-workshop-toolkit/routes/new-workshop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route;

  var _default = Route.extend({
    // draftWorkshop: null,
    sortOrder: 0,
    model: function model() {
      this.controllerFor('login').set('readyToSave', true);
      var draft = null;
      var duplicate = this.controllerFor('your-workshop-scripts').get('duplicateWorkshop');

      if (duplicate != null && duplicate.get('id') != null) {
        draft = this.get('store').createRecord('workshop', {
          introtitle: duplicate.get('introtitle'),
          wrapupduration: duplicate.get('wrapupduration')
        });
        duplicate.get('blocks').forEach(function (b) {
          b.copy(true, {
            overwrite: {
              workshop: draft
            }
          });
        });
      }

      this.controllerFor('your-workshop-scripts').set('duplicateWorkshop', null); // draft = duplicate || this.get('draftWorkshop');

      if (draft == null) {
        draft = this.get('store').createRecord('workshop', {
          introtitle: ''
        }); // let maxSortOrder = 0;
        // this.get('store').findAll('workshop-template').then((workshopTemplate)=> {
        //     workshopTemplate.forEach((t) => {
        //         let blocks = duplicate.get('blocks');
        //         blocks.forEach((b) => {
        //             if (b.get('defaultblock')) {
        //               b.set('sortorder', maxSortOrder);
        //               b.copy({ workshop: draft });
        //               maxSortOrder++;
        //             }
        //           });
        //       });
        //   });
      } // this.set('draftWorkshop', draft);


      return {
        tasks: this.get('store').findAll('workshop-template'),
        currentWorkshop: draft
      };
    }
  });

  _exports.default = _default;
});