define("ux-workshop-toolkit/components/workshop-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    tagName: 'nav',
    classNames: ['workshop--navbar-wrapper'],
    mediaQueries: inject.service(),
    submenuActive: 'inactive',
    submenus: {
      totalTime: 'inactive',
      savedTime: 'inactive'
    },
    actions: {
      saveWorkshop: function saveWorkshop(currentWorkshop) {
        this.sendAction('saveWorkshop', currentWorkshop);
      },
      downloadPdf: function downloadPdf(currentWorkshop) {
        this.sendAction('downloadPdf', currentWorkshop);
      },
      toggleMobileMenu: function toggleMobileMenu(submenu) {
        var subs = Ember.get(this, 'submenus');
        var submenuClass = Ember.get(this, 'submenuActive');

        if (submenuClass === 'inactive') {
          // if everything is off, turn the wrapper and selected menu on.
          Ember.set(this, 'submenuActive', 'active');
          Ember.set(this, "submenus.".concat(submenu), 'active');
        } else if (subs[submenu] === 'active') {
          // else if the current submenu item is clicked again, turn everything off.
          Ember.set(this, 'submenuActive', 'inactive');

          for (var menu in subs) {
            if (subs.hasOwnProperty(menu)) {
              Ember.set(this, "submenus.".concat(menu), 'inactive');
            }
          }
        } else {
          // the submenu wrapper is open, but we want to change the display in the menu.
          for (var _menu in subs) {
            if (subs.hasOwnProperty(_menu)) {
              Ember.set(this, "submenus.".concat(_menu), 'inactive');
            }
          }

          Ember.set(this, "submenus.".concat(submenu), 'active');
        }
      }
    }
  });

  _exports.default = _default;
});