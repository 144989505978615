define("ux-workshop-toolkit/components/form-input-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    classNameBindings: ['showError:form-error'],
    // saveError: computed('hasError', function() {
    //   return (!!get(this, 'saveErrorMessage')) ? true : false;
    // }),
    showError: false,
    actions: {
      showErrors: function showErrors(val) {
        if (!val || val.length < 1) {
          Ember.set(this, 'showError', true);
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});