define("ux-workshop-toolkit/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base", "ux-workshop-toolkit/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
      $ = Ember.$,
      isEmpty = Ember.isEmpty,
      run = Ember.run;

  var _default = _base.default.extend({
    restore: function restore(data) {
      return new RSVP.Promise(function (resolve, reject) {
        if (isEmpty(data.token)) {
          reject();
        } else {
          resolve(data);
        }
      });
    },
    authenticate: function authenticate(username, password) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this.callLogin(username, password).then(function (response) {
          run(function () {
            resolve({
              token: response.access_token
            });
          });
        }, function ()
        /*xhr*/
        {
          run(null, reject, 'Could not authenticate.');
        });
      });
    },
    invalidate: function invalidate() {
      return new RSVP.Promise(function (resolve) {
        // data = {};
        resolve({});
      });
    },
    callLogin: function callLogin(paramusername, parampassword) {
      var url = "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/auth-token");
      var options = {
        url: url,
        type: 'POST',
        dataType: 'json',
        headers: {
          Accept: 'application/json'
        },
        data: {
          grant_type: 'password',
          username: paramusername,
          password: parampassword
        }
      };
      return $.ajax(options);
    }
  });

  _exports.default = _default;
});