define("ux-workshop-toolkit/serializers/workshop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    serialize: function serialize()
    /*snapshot, options*/
    {
      var json = this._super.apply(this, arguments);

      if (json.data.id === undefined || json.data.id === null) {
        json.data.id = 0;
      }

      return json;
    }
  });

  _exports.default = _default;
});