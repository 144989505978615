define("ux-workshop-toolkit/controllers/edit-workshop", ["exports", "ux-workshop-toolkit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$,
      Controller = Ember.Controller;

  var _default = Controller.extend({
    actions: {
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.set('currentModel.justDragged', draggedModel);
      },
      updateWorkshop: function updateWorkshop(workshop) {
        // debugger;
        this.get('store').peekRecord('workshop', workshop.get('id')).save().then(function (workshop) {
          workshop.get('blocks').forEach(function (block) {
            block.set('parentid', workshop.get('id'));
            block.save();
          }); // this.transitionToRoute('your-workshop-scripts').then((newRoute) => {
          //     let x = workshop.get('introtitle');
          //     newRoute.controllerFor('login').set('readyToSave', false);
          //     newRoute.controller.set('noticeMessage', `"${x}" - has been updated successfully`);
          //   });
        });
      },
      downloadPdf: function downloadPdf(currentWorkshop) {
        var blocks = currentWorkshop.get('blocks').sortBy('sortorder');
        var workshopObj = JSON.parse(JSON.stringify(currentWorkshop));
        workshopObj.blocks = blocks;
        $.ajax({
          method: 'POST',
          url: "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/workshopsdownload"),
          contentType: 'application/vnd.api+json',
          data: {
            json: JSON.stringify(workshopObj)
          }
        }).then(function (response) {
          window.open("".concat(_environment.default.api.host, "/temp/pdf/").concat(response));
        });
      }
    }
  });

  _exports.default = _default;
});