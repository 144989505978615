define("ux-workshop-toolkit/models/block", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;

  var _default = Model.extend(_emberDataCopyable.default, {
    parentid: attr(),
    category: attr('string'),
    title: attr('string'),
    shortintro: attr('string'),
    // Sidebar
    shortdescription: attr('string'),
    // Under the title in the component
    duration: attr('string'),
    longdescriptiontitle: attr('string'),
    // Instructions
    longdescription: attr('string'),
    // Instructions
    followup: attr('string'),
    material: attr('string'),
    blocktype: attr('string'),
    defaultblock: attr('boolean'),
    sortorder: attr(),
    workshop: belongsTo('workshop', {
      inverse: 'blocks'
    }),
    isGeneric: computed(function () {
      if (this.get('blocktype') === 'generic') {
        return true;
      }

      return false;
    }),
    isRecap: computed(function () {
      if (this.get('blocktype') === 'recap') {
        return true;
      }

      return false;
    }),
    isBreak: computed(function () {
      if (this.get('blocktype') === 'break') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});