define("ux-workshop-toolkit/components/header-global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Component = Ember.Component;

  var _default = Component.extend({
    session: inject.service('session'),
    canvasExpanded: false,
    actions: {
      closeMobileMenu: function closeMobileMenu(route) {
        this.sendAction('closeMobileMenu', route);
      },
      expandCanvas: function expandCanvas() {
        this.sendAction('expandCanvas');
        this.toggleProperty('canvasExpanded');
      },
      invalidateSession: function invalidateSession() {
        this.sendAction('invalidateSession');
      }
    }
  });

  _exports.default = _default;
});