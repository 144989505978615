define("ux-workshop-toolkit/components/app-version", ["exports", "ember-cli-app-version/components/app-version", "ux-workshop-toolkit/config/environment"], function (_exports, _appVersion, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var name = _environment.default.APP.name;
  var version = _environment.default.APP.version;

  var _default = _appVersion.default.extend({
    version: version,
    name: name
  });

  _exports.default = _default;
});