define("ux-workshop-toolkit/components/saved-workshop-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    actions: {
      deleteWorkshop: function deleteWorkshop(workshop) {
        this.sendAction('deleteWorkshop', workshop);
      },
      duplicateWorkshop: function duplicateWorkshop(workshop) {
        this.sendAction('duplicateWorkshop', workshop);
      }
    }
  });

  _exports.default = _default;
});