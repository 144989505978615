define("ux-workshop-toolkit/controllers/your-workshop-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Controller = Ember.Controller;

  var _default = Controller.extend({
    noticeMessage: '',
    duplicateWorkshop: null,
    persistedWorkhops: computed('model.workshops.@each.id', function () {
      var workshops = this.get('model.workshops');
      workshops = workshops.filter(function (workshop) {
        return workshop.get('id') !== null && workshop.get('id') !== 0;
      });
      workshops = workshops.sortBy('id').reverse();
      return workshops;
    }),
    // persistedWorkhops: computed.filter('model.workshops.@each.id', function(workshop) {
    //   return workshop.get('id') !== null && workshop.get('id') !== 0;
    // }),
    clearMessages: function clearMessages() {
      this.set('noticeMessage', '');
    },
    actions: {
      deleteWorkshop: function deleteWorkshop(workshop) {
        this.transitionToRoute('delete-workshop').then(function (newRoute) {
          newRoute.controller.set('workshop', workshop);
        });
      },
      duplicateWorkshop: function duplicateWorkshop(workshop) {
        this.set('duplicateWorkshop', workshop);
        this.transitionToRoute('new-workshop').then(function (newRoute) {
          newRoute.controller.set('duplicateWorkshop', workshop);
        });
      }
    }
  });

  _exports.default = _default;
});