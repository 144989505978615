define("ux-workshop-toolkit/routes/login-success", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route;

  var _default = Route.extend(_authenticatedRouteMixin.default, {});

  _exports.default = _default;
});