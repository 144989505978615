define("ux-workshop-toolkit/routes/application", ["exports", "ux-workshop-toolkit/config/environment", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _environment, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      testing = Ember.testing;

  var _default = Route.extend(_applicationRouteMixin.default, {
    // Override to make sure we use 'rootURL' not 'baseURL'.
    // @see https://github.com/simplabs/ember-simple-auth/issues/1076
    sessionInvalidated: function sessionInvalidated() {
      if (!testing) {
        window.location.replace(_environment.default.rootURL);
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (window) {
        window.onbeforeunload = function () {
          if (_this.controllerFor('login').get('readyToSave')) {
            return 'done';
          }
        };
      }
    },
    actions: {
      invalidateSession: function invalidateSession() {
        if (window && this.controllerFor('login').get('readyToSave')) {
          if (window.confirm('Are you sure to logout? You will lose any unsaved data.\n\nClick \'OK\' to logout or \'Cancel\' to stay in this page.')) {
            window.onbeforeunload = null;
            this.get('session').invalidate();
          }
        } else {
          this.get('session').invalidate();
        }
      }
    }
  });

  _exports.default = _default;
});