define("ux-workshop-toolkit/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "ux-workshop-toolkit/templates/reset-password.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel white small margin-top-30 margin-bottom-30 login-form");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "margin-bottom-10 margin-top-10");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("small");
        dom.setAttribute(el5, "class", "text-success success");
        var el6 = dom.createElement("b");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("small");
        dom.setAttribute(el5, "class", "text-info");
        var el6 = dom.createElement("b");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("small");
        dom.setAttribute(el5, "class", "error text-danger");
        var el6 = dom.createElement("b");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "hidden");
        dom.setAttribute(el4, "id", "token");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "button primary margin-top-30");
        dom.setAttribute(el4, "type", "submit");
        var el5 = dom.createTextNode("Reset Password");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [9]);
        var morphs = new Array(8);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createUnsafeMorphAt(dom.childAt(element1, [1, 0]), 0, 0);
        morphs[2] = dom.createUnsafeMorphAt(dom.childAt(element1, [3, 0]), 0, 0);
        morphs[3] = dom.createUnsafeMorphAt(dom.childAt(element1, [5, 0]), 0, 0);
        morphs[4] = dom.createMorphAt(element0, 3, 3);
        morphs[5] = dom.createMorphAt(element0, 5, 5);
        morphs[6] = dom.createMorphAt(element0, 7, 7);
        morphs[7] = dom.createUnsafeAttrMorph(element2, 'value');
        return morphs;
      },
      statements: [["element", "action", ["resetpassword"], ["on", "submit"], ["loc", [null, [3, 14], [3, 52]]], 0, 0], ["content", "successMessage", ["loc", [null, [5, 55], [5, 75]]], 0, 0, 0, 0], ["content", "noticeMessage", ["loc", [null, [6, 44], [6, 63]]], 0, 0, 0, 0], ["content", "errorMessage", ["loc", [null, [7, 52], [7, 70]]], 0, 0, 0, 0], ["inline", "form-input-wrapper", [], ["id", "email", "value", ["subexpr", "@mut", [["get", "email", ["loc", [null, [10, 50], [10, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Email*", "error", ["subexpr", "@mut", [["get", "errors.email", ["loc", [null, [10, 90], [10, 102]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 12], [10, 104]]], 0, 0], ["inline", "form-input-wrapper", [], ["type", "password", "id", "password", "value", ["subexpr", "@mut", [["get", "password", ["loc", [null, [11, 69], [11, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Password*", "error", ["subexpr", "@mut", [["get", "errors.password", ["loc", [null, [11, 115], [11, 130]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 12], [11, 132]]], 0, 0], ["inline", "form-input-wrapper", [], ["type", "password", "id", "confirmpassword", "value", ["subexpr", "@mut", [["get", "confirmpassword", ["loc", [null, [12, 76], [12, 91]]], 0, 0, 0, 0]], [], [], 0, 0], "labelContent", "Enter Confirm Password*", "error", ["subexpr", "@mut", [["get", "errors.confirmpassword", ["loc", [null, [12, 137], [12, 159]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 12], [12, 161]]], 0, 0], ["attribute", "value", ["get", "token", ["loc", [null, [13, 53], [13, 58]]], 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());

  _exports.default = _default;
});