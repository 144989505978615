define("ux-workshop-toolkit/components/workshop-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      run = Ember.run,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['page-content'],
    classNameBindings: ['saveError:save-error'],
    saveError: computed('saveErrorMessage', function () {
      return !!Ember.get(this, 'saveErrorMessage') ? true : false;
    }),
    durationError: {
      active: false,
      message: null,
      class: null
    },
    totalWorkshopMinutes: Infinity,
    mediaQueries: inject.service(),
    totalWorkshopTime: computed('totalWorkshopMinutes', function () {
      var time = 0;
      var currentWorkshop = Ember.get(this, 'currentWorkshop');

      if (currentWorkshop.get('blocks') != null) {
        currentWorkshop.get('blocks').forEach(function (b) {
          time += parseInt(b.get('duration'));
        });
      }

      var wrap = parseInt(currentWorkshop.get('wrapupduration'));

      if (!isNaN(wrap)) {
        time += wrap;
      }

      Ember.set(this, 'totalWorkshopMinutes', time);
      return {
        totalMinutesFormatted: (time / 60 > 1 ? parseInt(time / 60) + " hour(s) " : "") + (time % 60 > 0 ? parseInt(time % 60) + " minute(s)" : ""),
        totalMinutes: time
      };
    }),
    lastSavedTime: '',
    saveErrorMessage: '',
    actions: {
      setErrors: function setErrors() {
        if (!Ember.get(Ember.get(this, 'currentWorkshop'), 'introtitle')) {
          Ember.set(this, 'saveErrorMessage', 'Please, add a title for your workshop to be able to save this script into your account');
          return;
        }

        Ember.set(this, 'saveErrorMessage', '');
        return;
      },
      clearErrors: function clearErrors() {
        Ember.set(this, 'saveErrorMessage', null);
      },
      updateTotalTime: function updateTotalTime(val) {
        if (isNaN(Math.floor(val))) {
          Ember.set(this, 'durationError.message', 'Duration must be numeric');
          Ember.set(this, 'durationError.active', true);
          Ember.set(this, 'durationError.class', 'error');
          return false;
        } else if (val < 0) {
          Ember.set(this, 'durationError.message', 'Time must be positive');
          Ember.set(this, 'durationError.active', true);
          Ember.set(this, 'durationError.class', 'error');
          return false;
        }

        Ember.set(this, 'durationError.active', false);
        Ember.set(this, 'durationError.message', null);
        Ember.set(this, 'durationError.class', null);
        var x = Ember.get(this, 'totalWorkshopMinutes');
        Ember.set(this, 'totalWorkshopMinutes', x === Infinity || isNaN(x) ? parseInt(val, 10) : parseInt(x, 10) + parseInt(val, 10));
      },
      updateTime: function updateTime() {
        Ember.set(this, 'totalWorkshopMinutes', Ember.get(this, 'totalWorkshopTime.totalMinutes'));
      },
      saveWorkshop: function saveWorkshop(currentWorkshop) {
        this.send('setErrors');

        if (Ember.get(this, 'saveErrorMessage').length > 0) {
          return false;
        }

        this.sendAction('saveWorkshop', currentWorkshop);
        run.next(this, function () {
          Ember.set(this, 'lastSavedTime', "Last changes saved at ".concat(currentWorkshop.get('lastmodifieddate')));
        });
      },
      downloadPdf: function downloadPdf(currentWorkshop) {
        this.sendAction('downloadPdf', currentWorkshop);
      },
      moveBlock: function moveBlock(itemModels) {
        itemModels.forEach(function (item, index) {
          item.set('sortorder', index);
        });
        Ember.set(this, 'currentWorkshop.blocks', itemModels);
      }
    }
  });

  _exports.default = _default;
});