define("ux-workshop-toolkit/templates/your-workshop-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 13,
              "column": 6
            }
          },
          "moduleName": "ux-workshop-toolkit/templates/your-workshop-scripts.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "saved-workshop-list", [], ["w", ["subexpr", "@mut", [["get", "w", ["loc", [null, [12, 34], [12, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "deleteWorkshop", "deleteWorkshop", "duplicateWorkshop", "duplicateWorkshop"], ["loc", [null, [12, 10], [12, 107]]], 0, 0]],
        locals: ["w"],
        templates: []
      };
    }();

    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 6
          }
        },
        "moduleName": "ux-workshop-toolkit/templates/your-workshop-scripts.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel medium");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4, "class", "title secondary text-center");
        var el5 = dom.createTextNode("Your workshop scripts");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "margin-bottom-10 text-info text-info-16");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("b");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "margin-bottom-50");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel secondary medium");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "class", "hand-written");
        var el3 = dom.createTextNode("Need some inspiration? Check out some sample workshop scripts…");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row around-sm sample-script-block margin-top-30");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm panel flex-col between-sm small margin-x-10 text-center bordered");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "sample-script-title");
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "no-margin-top text-primary");
        var el6 = dom.createTextNode("\"Co-design workshop - homepage redesign\" workshop script");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "class", "button primary margin-top-20");
        dom.setAttribute(el4, "role", "button");
        var el5 = dom.createTextNode("Download PDF");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm panel flex-col between-sm small margin-x-10 text-center bordered");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "sample-script-title");
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "no-margin-top text-primary");
        var el6 = dom.createTextNode("\"Requirements Workshop - University Student Portal Redesign\" workshop script");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "class", "button primary margin-top-20");
        dom.setAttribute(el4, "role", "button");
        var el5 = dom.createTextNode("Download PDF");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm panel flex-col between-sm small margin-x-10 text-center bordered");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "sample-script-title");
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "no-margin-top text-primary");
        var el6 = dom.createTextNode("\"Research workshop - Pet insurance existing customers\" workshop script");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "class", "button primary  margin-top-20");
        dom.setAttribute(el4, "role", "button");
        var el5 = dom.createTextNode("Download PDF");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2, 3]);
        var element2 = dom.childAt(element1, [1, 3]);
        var element3 = dom.childAt(element1, [3, 3]);
        var element4 = dom.childAt(element1, [5, 3]);
        var morphs = new Array(5);
        morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element0, [3, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        morphs[2] = dom.createAttrMorph(element2, 'href');
        morphs[3] = dom.createAttrMorph(element3, 'href');
        morphs[4] = dom.createAttrMorph(element4, 'href');
        return morphs;
      },
      statements: [["content", "noticeMessage", ["loc", [null, [8, 7], [8, 26]]], 0, 0, 0, 0], ["block", "each", [["get", "persistedWorkhops", ["loc", [null, [11, 14], [11, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 6], [13, 15]]]], ["attribute", "href", ["concat", [["get", "rootURL", ["loc", [null, [24, 17], [24, 24]]], 0, 0, 0, 0], "assets/sample-scripts/Co-design_workshop_homepage_redesign.pdf"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["concat", [["get", "rootURL", ["loc", [null, [29, 17], [29, 24]]], 0, 0, 0, 0], "assets/sample-scripts/Requirements_Workshop-University_Student_Portal_Redesign.pdf"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["concat", [["get", "rootURL", ["loc", [null, [34, 17], [34, 24]]], 0, 0, 0, 0], "assets/sample-scripts/Research_workshop-Pet_insurance_existing_customers.pdf"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());

  _exports.default = _default;
});