define("ux-workshop-toolkit/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.toRoute('new-workshop'), this.use('toRight'));
    this.transition(this.fromRoute('new-workshop'), this.use('toLeft'));
    this.transition(this.inHelper('liquid-modal'), this.use('explode', {
      pick: '.lf-overlay',
      use: ['fade', {
        maxOpacity: 0.75
      }]
    }, {
      pick: '.lm-container',
      use: 'toDown'
    }));
  }
});