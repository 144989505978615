define("ux-workshop-toolkit/components/desktop-min-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Component.extend({
    mediaQueries: inject.service(),
    classNames: ['desktop-min-warning', 'panel', 'modal'],
    classNameBindings: ['isActive:active:inactive', 'mediaQueries.classNames'],
    isActive: computed('router.currentRouteName', function () {
      var route = this.get('router.currentRouteName');
      var routeTruth = route === 'new-workshop' || route.indexOf('edit-workshop') > -1;
      var deviceTruth = this.get('mediaQueries.isMobile') || this.get('mediaQueries.isTablet');
      return routeTruth && deviceTruth;
    }),
    actions: {
      dismissWarning: function dismissWarning() {
        console.debug('triggered');
        this.set('isActive', false);
      }
    }
  });

  _exports.default = _default;
});