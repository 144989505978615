define("ux-workshop-toolkit/templates/edit-workshop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 17
          }
        },
        "moduleName": "ux-workshop-toolkit/templates/edit-workshop.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 2, 2);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "workshop-content", [], ["saveWorkshop", "updateWorkshop", "downloadPdf", "downloadPdf", "tasks", ["subexpr", "@mut", [["get", "model.tasks", ["loc", [null, [6, 10], [6, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "currentWorkshop", ["subexpr", "@mut", [["get", "model.currentWorkshop", ["loc", [null, [7, 20], [7, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 2], [8, 4]]], 0, 0], ["content", "liquid-outlet", ["loc", [null, [10, 0], [10, 17]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());

  _exports.default = _default;
});