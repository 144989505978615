define("ux-workshop-toolkit/models/member", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend(_emberDataCopyable.default, {
    firstname: attr('string'),
    lastname: attr('string'),
    organization: attr('string'),
    email: attr('string'),
    password: attr('string')
  });

  _exports.default = _default;
});