define("ux-workshop-toolkit/controllers/delete-workshop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller;

  var _default = Controller.extend({
    workshop: null,
    actions: {
      deleteWorkshop: function deleteWorkshop() {
        var _this = this;

        var workshop = this.get('workshop');

        if (workshop !== null) {
          workshop.get('blocks').forEach(function (block) {
            // block.set('workshop', null);
            block.destroyRecord();
          });
          workshop.destroyRecord().then(function () {
            _this.transitionToRoute('your-workshop-scripts').then(function (newRoute) {
              newRoute.controller.set('noticeMessage', 'Workshop has been deleted successfully.');
            });
          });
        } else {
          this.transitionToRoute('your-workshop-scripts');
        }
      }
    }
  });

  _exports.default = _default;
});