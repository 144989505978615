define("ux-workshop-toolkit/routes/profile", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route;

  var _default = Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.findRecord('member', 'profile');
    },
    actions: {
      updateProfile: function updateProfile(member) {
        member.save();
      }
    }
  });

  _exports.default = _default;
});