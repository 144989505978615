define("ux-workshop-toolkit/routes/edit-workshop", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      RSVP = Ember.RSVP;

  var _default = Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      this.controllerFor('login').set('readyToSave', true);
      var store = this.get('store');
      return RSVP.hash({
        currentWorkshop: store.findRecord('workshop', params.id),
        tasks: store.findAll('workshop-template')
      });
    }
  });

  _exports.default = _default;
});