define("ux-workshop-toolkit/components/footer-global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({});

  _exports.default = _default;
});