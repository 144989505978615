define("ux-workshop-toolkit/controllers/reset-password", ["exports", "ember-validations", "ux-workshop-toolkit/config/environment"], function (_exports, _emberValidations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller;

  var _default = Controller.extend(_emberValidations.default, {
    queryParams: ['token'],
    token: null,
    email: null,
    password: null,
    confirmpassword: null,
    errorMessage: null,
    successMessage: '',
    noticeMessage: '',
    sourceRoute: null,
    clearMessages: function clearMessages() {
      this.set('errorMessage', '');
      this.set('successMessage', '');
      this.set('noticeMessage', '');
    },
    validations: {
      'email': {
        presence: {
          message: 'Email can not be empty.'
        }
      },
      'password': {
        presence: {
          message: 'Password can not be empty.'
        },
        length: {
          minimum: 8,
          messages: {
            tooShort: 'Password must at least 8 characters.'
          }
        }
      },
      'confirmpassword': {
        presence: {
          message: 'Confirm Password can not be empty.'
        },
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('password') !== this.model.get('confirmpassword')) {
            return 'Password and Confirm Password must be same.';
          }
        })
      }
    },
    actions: {
      resetpassword: function resetpassword() {
        var _this2 = this;

        var _this$getProperties = this.getProperties('email', 'password', 'token'),
            email = _this$getProperties.email,
            password = _this$getProperties.password,
            token = _this$getProperties.token;

        this.set('successMessage', '');
        this.set('noticeMessage', '');
        this.set('errorMessage', '');

        var _this = this;

        this.validate().then(function () {
          var model = {
            email: email,
            password: password,
            token: token
          };
          Ember.$.ajax({
            method: 'POST',
            url: "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/members/reset-password/"),
            contentType: 'application/vnd.api+json',
            data: {
              json: JSON.stringify(model)
            }
          }).fail(function (jqXHR
          /* , textStatus, errorThrown */
          ) {
            if (jqXHR.status === 404) {
              _this.set('errorMessage', 'Email address not found.');
            } else if (jqXHR.status === 406) {
              _this.set('errorMessage', 'Password validation failure. Please try a stronger password.');
            } else if (jqXHR.status === 500) {
              _this.set('errorMessage', 'An error occured while processing your request. Please contact our support.');
            } else if (jqXHR.status === 403) {
              _this.set('errorMessage', 'Invalid token.');
            }
          }).success(function ()
          /* jqXHR */
          {
            _this.transitionToRoute('reset-password-success');
          });
        }).catch(function ()
        /* error */
        {
          if (!_this.get('isValid')) {
            if (_this.get('errors.email').length) {
              _this2.set('errorMessage', "".concat(_this2.get('errorMessage')).concat(_this.get('errors.email')[0], "<br />"));
            }

            if (_this.get('errors.password').length) {
              _this2.set('errorMessage', "".concat(_this2.get('errorMessage')).concat(_this.get('errors.password')[0], "<br />"));
            }

            if (_this.get('errors.confirmpassword').length) {
              _this2.set('errorMessage', "".concat(_this2.get('errorMessage')).concat(_this.get('errors.confirmpassword')[0], "<br />"));
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});