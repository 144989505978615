define("ux-workshop-toolkit/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ux-workshop-toolkit/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:custom',
    host: _environment.default.api.host,
    namespace: _environment.default.api.namespace
  });

  _exports.default = _default;
});