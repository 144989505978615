define("ux-workshop-toolkit/components/workshop-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    mediaQueries: inject.service(),
    actions: {
      addBlock: function addBlock() {
        this.sendAction('toggleMobileMenu', 'addSection');
      }
    }
  });

  _exports.default = _default;
});