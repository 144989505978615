define("ux-workshop-toolkit/models/workshop", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_emberDataCopyable.default, {
    introtitle: attr('string'),
    introduration: attr('string'),
    introshortdescription: attr('string'),
    introlongdescription: attr('string'),
    objectivestext: attr('string'),
    wrapupduration: attr('string'),
    participants: attr('string'),
    datestartingspace: attr('string'),
    lastmodifieddate: attr('string'),
    blocks: hasMany('block')
  });

  _exports.default = _default;
});