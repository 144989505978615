define("ux-workshop-toolkit/controllers/register", ["exports", "ember-validations"], function (_exports, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      inject = Ember.inject;

  var _default = Controller.extend(_emberValidations.default, {
    username: null,
    password: null,
    errorMessage: null,
    session: inject.service('session'),
    validations: {
      'model.email': {
        presence: {
          message: 'Email can not be empty.'
        }
      },
      'model.password': {
        presence: {
          message: 'Password can not be empty.'
        }
      }
    },
    actions: {
      registerMember: function registerMember(member) {
        var _this = this;

        this.set('errorMessage', '');
        this.validate().then(function () {
          member.save().then(function () {
            _this.transitionToRoute('login').then(function (newRoute) {
              newRoute.controller.set('successMessage', 'Registration complete<br/>');
            });
          }, function (resp) {
            if (resp.hasOwnProperty('errors') && resp.errors.length > 0) {
              _this.set('errorMessage', resp.errors[0].detail);
            } else {
              _this.set('errorMessage', 'Failed to register. <br/> Please email <a href="mailto:ux-tool-support@sitback.com.au">ux-tool-support@sitback.com.au</a> with your First name, Last name and Email address.');
            }
          });
        }).catch(function () {
          _this.set('errorMessage', 'Required information is missing.');
        });
      }
    }
  });

  _exports.default = _default;
});