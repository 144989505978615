define("ux-workshop-toolkit/authorizers/custom", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authorize: function authorize(sessionData, block) {
      // console.warn('sess', sessionData);
      block('Authorization', "Bearer ".concat(sessionData.token)); // block('Authorization', sessionData.token);
    }
  });

  _exports.default = _default;
});