define("ux-workshop-toolkit/components/activity-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject;

  var _default = Component.extend({
    tagName: '',
    store: inject.service(),
    actions: {
      addBlock: function addBlock(block) {
        var currentWorkshop = this.get('currentWorkshop');
        var maxSortOrder = 0;
        var totalTime = 0;
        currentWorkshop.get('blocks').forEach(function (b) {
          if (parseInt(b.get('sortorder')) > maxSortOrder) {
            maxSortOrder = parseInt(b.get('sortorder'));
          }

          totalTime += parseInt(b.get('duration'));
        });
        block.set('sortorder', maxSortOrder + 1);
        block.copy(true, {
          overwrite: {
            workshop: currentWorkshop
          }
        });
        this.sendAction('addBlock', block);
      }
    }
  });

  _exports.default = _default;
});