define("ux-workshop-toolkit/components/feedback-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    didInsertElement: function didInsertElement() {
      hbspt.forms.create({
        portalId: '2240729',
        formId: '4558fa94-51df-40da-8e44-f3313265d7a0',
        target: '.hbspt-feedback'
      });
    }
  });

  _exports.default = _default;
});