define("ux-workshop-toolkit/controllers/forgot-password", ["exports", "ux-workshop-toolkit/config/environment", "ember-validations"], function (_exports, _environment, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller,
      $ = Ember.$;

  var _default = Controller.extend(_emberValidations.default, {
    email: null,
    errorMessage: null,
    validations: {
      'email': {
        presence: {
          message: 'Email can not be empty.'
        }
      }
    },
    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        this.set('errorMessage', '');

        var _this$getProperties = this.getProperties('email'),
            email = _this$getProperties.email;

        var _this = this;

        this.validate().then(function () {
          var model = {
            email: email,
            url: "".concat(_environment.default.APP.host, "/reset-password/")
          };
          $.ajax({
            method: 'POST',
            url: "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/members/forgot-password/"),
            contentType: 'application/vnd.api+json',
            data: {
              json: JSON.stringify(model)
            }
          }).fail(function (jqXHR
          /* , textStatus  , errorThrown */
          ) {
            if (jqXHR.status === 404) {
              _this.set('errorMessage', 'Email address not found.');
            } else if (jqXHR.status === 500) {
              _this.set('errorMessage', 'An error occured while processing your request. Please contact our support.');
            }
          }).success(function ()
          /* jqXHR */
          {
            _this.transitionToRoute('forgot-password-success');
          });
        }).catch(function ()
        /* error */
        {
          if (!_this.get('isValid')) {
            if (_this.get('errors.email').length) {
              _this2.set('errorMessage', _this.get('errors.email')[0]);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});