define("ux-workshop-toolkit/components/activity-workshop-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      run = Ember.run,
      inject = Ember.inject;

  var _default = Component.extend({
    scroller: inject.service(),
    mediaQueries: inject.service(),
    classNames: ['activity-panel row'],
    classNameBindings: ['isNew:new', 'isBreakPanel:secondary:white'],
    isNew: true,
    durationError: {
      active: false,
      message: null,
      class: null
    },
    isBreakPanel: false,
    didInsertElement: function didInsertElement() {
      run.next(this, function () {
        Ember.set(this, 'isBreakPanel', Ember.get(this, 'activity.isBreak'));
      });
      run.later(this, function () {
        this.set('isNew', false);
      }, 3000);
      this.get('scroller').scrollVertical("#".concat(this.elementId), {
        duration: 500,
        easing: 'linear',
        offset: -50
      });
    },
    actions: {
      dragStopped: function dragStopped(a, b, c) {
        console.debug('dropfunction', a, b, c);
      },
      updateTime: function updateTime(val) {
        this.sendAction('updateTotalTime', val);
      },
      clearErrors: function clearErrors() {
        this.sendAction('clearErrors');
      },
      updateTotalTime: function updateTotalTime(val) {
        if (isNaN(Math.floor(val))) {
          Ember.set(this, 'durationError.message', 'Duration must be numeric');
          Ember.set(this, 'durationError.active', true);
          Ember.set(this, 'durationError.class', 'error');
          return false;
        } else if (val < 0) {
          Ember.set(this, 'durationError.message', 'Time must be positive');
          Ember.set(this, 'durationError.active', true);
          Ember.set(this, 'durationError.class', 'error');
          return false;
        }

        Ember.set(this, 'durationError.active', false);
        Ember.set(this, 'durationError.message', null);
        Ember.set(this, 'durationError.class', null);
        this.sendAction('updateTotalTime', val);
      },
      removeBlock: function removeBlock(block) {
        this.sendAction('updateTotalTime', -block.duration);
        block.set('workshop', null);
        block.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});