define("ux-workshop-toolkit/controllers/new-workshop", ["exports", "ux-workshop-toolkit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$,
      Controller = Ember.Controller,
      inject = Ember.inject;

  var _default = Controller.extend({
    session: inject.service('session'),
    actions: {
      insertWorkshop: function insertWorkshop(newWorkshop) {
        // debugger;
        var session = this.get('session.isAuthenticated');

        if (session) {
          newWorkshop.save().then(function (workshop) {
            workshop.get('blocks').forEach(function (block, index) {
              // debugger;
              block.set('parentid', workshop.get('id'));
              block.set('sortorder', index);
              block.save();
            }); // this.transitionToRoute('your-workshop-scripts').then((newRoute) => {
            //     let workshopTitle = workshop.get('introtitle');
            //     newRoute.controllerFor('login').set('readyToSave', false);
            //     newRoute.controller.set('noticeMessage', `"${workshopTitle}" - has been saved successfully`);
            //   });
          });
        } else {
          // debugger;
          newWorkshop.set('readyToSave', true);
          this.transitionToRoute('login').then(function (newRoute) {
            newRoute.controller.set('noticeMessage', 'Please login to save your workshop');
            newRoute.controller.set('readyToSave', true);
          });
        }
      },
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.set('currentModel.justDragged', draggedModel);
      },
      downloadPdf: function downloadPdf(currentWorkshop) {
        var blocks = currentWorkshop.get('blocks').sortBy('sortorder');
        var workshopObj = JSON.parse(JSON.stringify(currentWorkshop));
        workshopObj.blocks = blocks;
        $.ajax({
          method: 'POST',
          url: "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/workshopsdownload"),
          contentType: 'application/vnd.api+json',
          data: {
            json: JSON.stringify(workshopObj)
          }
        }).then(function (response) {
          window.open("".concat(_environment.default.api.host, "/temp/pdf/").concat(response));
        });
      }
    }
  });

  _exports.default = _default;
});