define("ux-workshop-toolkit/routes/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route;

  var _default = Route.extend({
    newMember: null,
    model: function model() {
      var draft = this.get('newMember');

      if (draft == null || draft.get('id') != null) {
        draft = this.get('store').createRecord('member');
        this.set('newMember', draft);
      }

      return draft;
    }
  });

  _exports.default = _default;
});